.alertlog{
    
    width: 100%;
    font-family: Helvetica, Arial, sans-serif;  
    overflow: scroll;
    display: flex;
    justify-content: center;
    align-items: center;
    .ag-body{       
        background: #FFF;
        height: 100vh;
        box-shadow: 1px 1px 1px 1px #CDCDCD;
        overflow-y: auto;
        
     .ag-header{
        height: 84px;
        background: #242b3d;
        display: flex;
        justify-content: center;
        align-items: center;
        img{
            max-width: 136px;
        }
     } 
     .ag-title{
         height: 142px;
         display: flex;
         flex-direction: column;
         justify-content: center;
         align-items: center;
         background: #126de5;
         color: #FFF;
         font-size: 36px;
         .ag-small-title{
             font-size: 19px;
         }
     } 
     .ag-content{
        width: 90%;
        border: 1px solid #d3dce0;
        margin: 2.5% 5%;
        border-radius: 5px;
        padding: 16px;
        .ag-content-title{
            font-family: Helvetica, Arial, sans-serif;
            margin-top: 0px;
            margin-bottom: 0px;
            font-size: 16px;
            line-height: 24px;
            color: #424651;
        }
        .items{
            margin-top: 10px;
            .item{
                display: flex;
                .title{
                              
                    font-size: 14px;
                    line-height: 21px;
                    color: #a0a3ab;
                }
                .value{
                    flex: 1;
                    text-align: right;
                    font-size: 14px;
                    line-height: 21px;
                    color: #a0a3ab;
                }
            }
        }
     }
     .ag-footer{
        background: #242b3d;
        padding: 32px 16px;
        color: #a0a3ab;
        line-height: 35px;
        .ag-link{
            line-height: 20px;
            a{
                color: #a0a3ab;
            }
        }
        .ag-social-media{
            display: flex;
            width: 100%;
            justify-content: flex-end;
            a{
                font-size: 30px;
                color: #dedede;
            }
        }
     } 
     
    }
}
@primary-color: #038fde;