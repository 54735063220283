.entity-panel {
    width: 100%;
    background: white;
    min-height: 100vh;
}

body {
    overflow: auto !important;
}

.disabled {
    pointer-events: none;
    opacity: 0.5;
}

.entity-panel-header {
    display: flex;
    align-items: center;
    min-height: 72px;
    flex-wrap: wrap;
    box-shadow: 0 0 4px 4px rgba(0, 0, 0, 0.08);

    .logo-entity {
        min-height: 72px;
        width: 280px;
        background: #131b2e;
        display: flex;
        align-items: center;
        column-gap: 20px;
        padding-left: 30px;

        @media screen and (max-width: 1366px) {
            width: 240px;
        }

        @media (max-width: 576px) {
            width: 100vw;
            padding-left: 0;
            justify-content: center;
        }

        svg {
            width: 20px;
            height: 20px;
            background-size: 20px 20px;

            path {
                fill: aliceblue;
            }

            @media (max-width: 576px) {
                display: none;
            }
        }


    }

    .left-header {
        display: flex;
        column-gap: 15px;
        align-items: flex-start;
        padding-left: 13px;
        flex-wrap: wrap;
        flex: 1;
        flex-direction: column;

        .entity-name {
            font-weight: 500;
            font-size: 16px;
            position: relative;
            width: 100%;
            word-break: break-all;
        }
        .entity-name-click {
            font-weight: 500;
            font-size: 16px;
            position: relative;
            width: 100%;
            color:black !important;            
            word-break: break-all;
            &:hover{
                color: #038fde !important;
            }
        }

        @media (max-width: 576px) {
            min-height: 72px;
            justify-content: center;
        }
    }


}

.entity-panel-content-tab {
    // padding: 15px;

    .ant-collapse-ghost {
        background: transparent;
        border: none;

    }
}

.ant-drawer-right .ant-drawer-content-wrapper {
    padding: 0 !important;
}

.refresh-action {
    display: flex;
    width: 100%;
    padding-right: 15px;
    justify-content: flex-end;
    align-items: center;
    height: 30px;
    cursor: pointer;
}

.attribute-card {
    border-top: 4px solid #038fde;
    position: relative;
    width: 100%;
    min-height: 90px;
    box-sizing: border-box;
    border-radius: 4px;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
    transition: 0.3s;
    padding: 10px;

    &:hover {
        box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
    }

    .header-card {
        display: flex;
        cursor: pointer;

        .information {
            flex: 1;
            display: flex;
            flex-direction: column;
            row-gap: 10px;

            .values-data {
                display: flex;
                justify-content: space-between;

                .value {
                    font-weight: 400;
                }

                .date-time {
                    color: rgb(140, 140, 140);
                    font-size: 12px;
                }
            }

            .names {
                font-weight: 500;

                .device-name {
                    font-weight: 400;
                    text-overflow: ellipsis;
                    width: 100%;
                    overflow: hidden;
                    color: rgb(140, 140, 140);
                }

            }
        }
    }

    .acions-icons {
        margin-top: 10px;
        display: flex;
        border-top: 1px solid gainsboro;
        border-bottom: 1px solid gainsboro;
        margin-bottom: 10px;

        .acions-icons-item {
            height: 40px;
            flex: 1;
            display: flex;
            align-items: center;
            justify-content: center;
            cursor: pointer;

            &:hover {
                transform: scale(1.2);
            }

        }
    }


}

.property-card {

    position: relative;
    width: 100%;
    min-height: 90px;
    box-sizing: border-box;
    border-radius: 4px;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
    transition: 0.3s;
    padding: 10px;

    &:hover {
        box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
    }

    .header-card {
        display: flex;
        cursor: pointer;
        
        .information {
            flex: 1;
            display: flex;
            flex-direction: column;
            row-gap: 10px;

            .values-data {
                display: flex;
                justify-content: space-between;

                .value {
                    font-weight: 400;
                }

                .date-time {
                    color: rgb(140, 140, 140);
                    font-size: 12px;
                }
            }

            .names {
                font-weight: 500;

                .device-name {
                    text-overflow: ellipsis;
                    overflow: hidden;
                    width: 100%;
                    font-weight: 400;
                    color: rgb(140, 140, 140);
                }

            }
        }
    }

    .acions-icons {
        margin-top: 10px;
        display: flex;
        border-top: 1px solid gainsboro;
        border-bottom: 1px solid gainsboro;
        margin-bottom: 10px;

        .acions-icons-item {
            height: 40px;
            flex: 1;
            display: flex;
            align-items: center;
            justify-content: center;
            cursor: pointer;

            &:hover {
                transform: scale(1.2);
            }

        }
    }

    .content-card {
        display: flex;
        flex-direction: column;
        row-gap: 2px;
        padding-top: 5px;
        padding-bottom: 10px;

        .date-time {
            color: rgb(140, 140, 140);
            font-size: 12px;
            margin-bottom: 5px;

        }

        .modify-user {
            color: rgb(140, 140, 140);
            font-size: 12px;
        }

        .file-name {
            margin-bottom: 10px;
            margin-top: 5px;
            overflow-wrap: break-word;
        }

        .description {}
    }

}

.element-card {

    position: relative;
    width: 100%;
    min-height: 30px;
    box-sizing: border-box;
    border-radius: 4px;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
    transition: 0.3s;
    padding: 10px;

    // &:hover {
    //     box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
    // }

    .header-card {
        display: flex;
        width: 100%;
        .information {
            flex: 1;
            display: flex;
            flex-direction: column;
            row-gap: 10px;
            width: 100%;
            .values-data {
                .element-name {
                    font-weight: 500;
                    line-height: 20px;
                    overflow: hidden;
                    white-space: nowrap;
                    text-overflow: ellipsis;
                    width: 100%;
                }

                .model-name {
                    font-weight: 400;
                    overflow: hidden;
                    white-space: nowrap;
                    text-overflow: ellipsis;
                    width: 100%;
                    font-size: 13px;
                    color: rgb(140, 140, 140);
                }
            }

            .names {
                font-weight: 500;

                .device-name {
                    font-weight: 400;
                    color: rgb(140, 140, 140);
                }

            }
        }
    }

    .acions-icons {
        margin-top: 10px;
        display: flex;
        border-top: 1px solid gainsboro;
        border-bottom: 1px solid gainsboro;
        margin-bottom: 10px;

        .acions-icons-item {
            height: 40px;
            flex: 1;
            display: flex;
            align-items: center;
            justify-content: center;
            cursor: pointer;

            &:hover {
                transform: scale(1.2);
            }

        }
    }

    .content-card {
        display: flex;
        flex-direction: column;
        row-gap: 2px;
        padding-top: 5px;
        padding-bottom: 10px;

        .date-time {
            color: rgb(140, 140, 140);
            font-size: 12px;
            margin-bottom: 5px;

        }

        .modify-user {
            color: rgb(140, 140, 140);
            font-size: 12px;
        }

        .file-name {
            margin-bottom: 10px;
            margin-top: 5px;
            overflow-wrap: break-word;
        }

        .description {}
    }

}

.insight-card {
    border-top: 4px solid #57408e;
    position: relative;
    width: 100%;
    min-height: 90px;
    box-sizing: border-box;
    border-radius: 4px;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
    transition: 0.3s;
    padding: 10px;

    &:hover {
        box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
    }

    .header-card {
        display: flex;
        cursor: pointer;

        .information {
            flex: 1;
            display: flex;
            flex-direction: column;
            row-gap: 10px;

            .values-data {
                display: flex;
                justify-content: space-between;

                .value {
                    font-weight: 400;
                }

                .date-time {
                    color: rgb(140, 140, 140);
                    font-size: 12px;
                }
            }

            .names {
                font-weight: 500;
                margin-top: 7px;

                .device-name {
                    font-weight: 400;
                    color: rgb(140, 140, 140);
                }

            }
        }
    }

    .acions-icons {
        margin-top: 10px;
        display: flex;
        border-top: 1px solid gainsboro;
        border-bottom: 1px solid gainsboro;
        margin-bottom: 10px;

        .acions-icons-item {
            height: 40px;
            flex: 1;
            display: flex;
            align-items: center;
            justify-content: center;
            cursor: pointer;

            &:hover {
                transform: scale(1.2);
            }

        }
    }

}

.ant-tabs-vertical.ant-tabs-card {
    .ant-tabs-nav-container {
        height: 100% !important;
    }
}

.divider-more-container {
    display: flex;
    align-items: center;
    justify-content: center;

    .divider-more {
        height: 80%;
        background: gainsboro;
        width: 1px;
    }
}

.location-tab {
    .type-entity {
        display: flex;
        column-gap: 50px;
        margin-bottom: 25px;
    }

    .coordinates {
        display: flex;
        flex-direction: column;
        row-gap: 25px;

        .coordinates-item {
            display: flex;
            flex-direction: column;
            row-gap: 10px;
        }

        .coordinates-item-line {
            display: flex;
            column-gap: 10px;
        }

        .part-two {
            padding-left: 15px;
        }
    }

    .edit-able {
        cursor: pointer;

        svg {
            path {
                stroke: #038fde;
            }

        }
    }

}

.place-viewer {
    height: 50px;
    display: flex;
    align-items: center;
    color: #038fde;
    cursor: pointer;
}

.entity-drawer {
    .ant-select-selector {
        border: none !important;
    }
}



//
// .mask-png-icon{
//     width: 20px;
//     min-height: 20px;
//     min-width: 20px;
//     height: 20px;
//     background-repeat: no-repeat;
//     background-size: cover;
//     margin-right: 5px;
// }
.mask-png-icon {
    width: 20px;
    height: 20px;
    margin-right: 5px;
    display: inline-block;
}

.img-org {
    width: 150px;
    height: 150px;
    display: inline-block;
    background: #fff;
    -webkit-mask: url("https://img.icons8.com/ios-glyphs/344/ffffff/book.png") center/contain;
    mask: url("https://img.icons8.com/ios-glyphs/344/ffffff/book.png") center/contain;
}

.sprite-item {
    height: 50px;
    display: flex;
    padding-left: 50px;
    align-items: center;
    width: 300px;
    max-width: 100%;
    column-gap: 5px;
    justify-content: space-between;

    .sprite-item-label {
        flex: 1;
    }
}

.sprite-item-color {
    height: 30px;
    width: 30px;
    margin-left: 15px;
    box-sizing: border-box;

}

.sprite-item-icon {
    background: #7b7b7b;

    img {
        object-fit: cover;
        border: 1px solid #7b7b7b;


    }
}

.custom-color-picker {
    .sketch-picker {
        box-shadow: none !important;
        width: 290px !important;
    }
}

.new-from {
    .ant-form-item-explain {
        display: none;
    }

}

.qrcode-content {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    row-gap: 15px;

    .name {
        font-weight: 600;
        font-size: 16px;
        line-height: 30px;
        max-width: 128px;
    }

    .qrcode {}

    .logo-site {
        width: 128px;
        height: 33px;
    }
}

//   @media print {
//     div {page-break-after: always;}
//   }

.pagination-styles {
    display: flex;
    height: 36px;
    align-items: center;
    width: 100%;
    justify-content: flex-end;

    .ant-pagination {
        height: 24px;
    }
}

.filter-styles {
    .ant-select {
        width: 100%;
    }

    .ant-select-selector {
        height: 36px !important;

        width: 100%;
    }
}

.link-card {
    border-top: 4px solid #8AD3D8;
}

.file-card {
    border-top: 4px solid #669EA3;
    position: relative;
    width: 100%;
    box-sizing: border-box;
    border-radius: 4px;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
    transition: 0.3s;
    padding: 10px 10px 0 10px;

    &:hover {
        box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
    }

    .header-card {
        display: flex;
        flex-direction: column;
        row-gap: 2px;

        .tag-name {
            font-weight: 500;
            color: black;
            line-height: 30px;
            cursor: pointer;
        }

        .line-one {
            display: flex;
            justify-content: space-between;
            align-items: center;

            .date-time {
                color: rgb(140, 140, 140);
                font-size: 12px;
            }

            .type {
                color: rgb(90, 181, 233);
                font-size: 12px;
                font-weight: 600;
            }
        }

        .creator {
            color: rgb(140, 140, 140);
            font-size: 12px;
        }

        .file-name {
            color: rgb(140, 140, 140);
            font-size: 12px;
            overflow: hidden;
            min-height: 15px;
            white-space: nowrap;
            text-overflow: ellipsis;
            width: 100%;
        }

        .title {
            margin-top: 10px;
            font-weight: 600;
        }

        .acions-icons {
            margin-top: 10px;
            display: flex;
            border-top: 1px solid gainsboro;

            .acions-icons-item {
                height: 40px;
                flex: 1;
                display: flex;
                align-items: center;
                justify-content: center;
                cursor: pointer;

                &:hover {
                    transform: scale(1.2);
                }

            }
        }

    }

    .content-card {
        display: flex;
        flex-direction: column;
        row-gap: 2px;
        padding-top: 5px;
        padding-bottom: 10px;
        border-top: 1px solid gainsboro;

        .date-time {
            color: rgb(140, 140, 140);
            font-size: 12px;
            margin-bottom: 5px;

        }

        .modify-user {
            color: rgb(140, 140, 140);
            font-size: 12px;
        }

        .file-name {
            margin-bottom: 10px;
            margin-top: 5px;
            overflow-wrap: break-word;
        }

        .description {}
    }

    .ant-typography {
        margin-bottom: 0;
    }
}

.attachment-tabs {

    .input-search,
    .ant-btn {
        margin-bottom: 10px !important;
    }
}


.more-actions-delete-property {
    display: flex;
    column-gap: 15px;
    align-items: center;
    justify-content: flex-end;
    width: 100%;
    height: 50px;
}

.remove-sprite-btn {
    color: red;
    line-height: 30px;
    cursor: pointer;
    margin-bottom: 10px;
    text-decoration: underline;
}

.ant-checkbox-group {
    width: 100%;
}
@primary-color: #038fde;